import React from 'react';
import { Link } from 'react-router-dom';
import buttonStyles from '../assets/scss/button.module.scss';

const linkButton = React.memo((props) => {
  return (
    <div className={buttonStyles.wrapper}>
      <Link to={props.to} className={buttonStyles.primaryButton} target={props.target}>
        {props.children}
      </Link>
    </div>
  );
});

export default linkButton;
