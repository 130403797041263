import styles from '../../assets/scss/store.module.scss';
import descStyles from '../../assets/scss/descStore.module.scss';
import LinkButton from '../linkButton';
import { useLocation } from 'react-router-dom';
import React from 'react';

const StoreDesc = React.memo(() => {
  const { state } = useLocation();
  const pattern = /^https:\/\/maps\.googleapis\.com\/maps\/api\/place\/photo\?maxwidth=400/g;
  const mapLink = `https://www.google.com/maps/search/?api=1&query_place_id=${state.store.place_id}&query=${state.store.geometry.location.lat},${state.store.geometry.location.lng}`;

  return (
    <div>
      <article className={`${styles.store} ${descStyles.store}`}>
        <h2 className={`${styles.storeName} ${descStyles.storeName}`}>{state.store.name}</h2>
        <div className={styles.storeBody}>
          <figure className={descStyles.storePicture}>
            {state.store.picture.match(pattern) ? (
              <img src={state.store.icon} alt="店のロゴマーク" />
            ) : (
              <img src={state.store.picture} alt="店のロゴマーク" />
            )}
          </figure>
          <div className={`${styles.storeText} ${descStyles.storeText}`}>
            <p>
              <span>ユーザー評価数：</span>
              {state.store.user_ratings_total ? state.store.user_ratings_total : 0}
            </p>
            <p>
              <span>評価（★1~5）：</span>
              {state.rateStar}
            </p>
            <p>
              <span>価格：</span>
              {state.priceStar}
            </p>
            <p>
              <span>住所：</span>
              {state.store.vicinity}
            </p>
            <p>
              <span>営業：</span>
              {state.store.opening_hours && state.store.opening_hours.open_now ? '営業中' : '準備中'}
            </p>
          </div>
        </div>
      </article>
      <LinkButton to={{ pathname: mapLink }} target="_blank">
        Google Mapで確認
      </LinkButton>
      <LinkButton to={{ pathname: '/', state: state.stores, selectedType: state.type, selectedRange: state.range, scroll: state.scroll }}>戻る</LinkButton>
    </div>
  );
});

export default StoreDesc;
