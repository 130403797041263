import React from 'react';
import styles from '../assets/scss/header.module.scss';

const Header = React.memo(() => {
  return (
    <header className={styles.header}>
      <h1>Take Out Store</h1>
    </header>
  );
});

export default Header;
